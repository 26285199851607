import { createContext, useContext, useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import Button from "./Button";
import "./List.scss";
import { ListContext } from "../views/ListView";
import { useDatabase } from "../contexts/DatabaseContext";

export var ItemsContext = createContext();
const initialFormFields = {
	name: "",
	description: "",
	amount: 1
};

function List({collection, data}){
	var { setData } = useDatabase();
	var {newItemModalOpen, setNewItemModalOpen} = useContext(ListContext);
	var [formFields, setFormFields] = useState(initialFormFields);
	var [checkbox, setCheckbox] = useState(false);

	var nameInputRef = useRef();
	var amountInputRef = useRef();

	function newItemAdd(e){
		e.preventDefault();
		if (!validateFormFields()) return;
		var path = "lists/" + collection + "/items";
		var isNew = true;
		if (formFields.id) {
			path += "/" + formFields.id;
			isNew = false;
		}
		setData(path, {
			title: formFields.name,
			text: formFields.description,
			amount: parseInt(formFields.amount),
			collected: false
		}, isNew);
		setFormFields(initialFormFields);
		nameInputRef.current.focus();
		(!checkbox || formFields.id) && setNewItemModalOpen(false);
	}

	function validateFormFields(){
		if (!formFields.name) {
			nameInputRef.current.focus();
			return false;
		}
		if (!formFields.amount) {
			amountInputRef.current.focus();
			return false;
		}
		return true;
	}

	function newItemCancel(){
		setNewItemModalOpen(false);
	}

	function incrementAmountInput(){
		var amount = parseInt(formFields.amount);
		if (isNaN(amount)) {
			amount = 0;
		}
		amount++;
		setFormFields(prev => ({
			...prev,
			amount
		}));
	}

	function decrementAmountInput(){
		var amount = parseInt(formFields.amount);
		if (isNaN(amount) || amount < 2) {
			amount = 1;
		} else {
			amount--;
		}
		setFormFields(prev => ({
			...prev,
			amount
		}));
	}

	useEffect(() => {
		if (newItemModalOpen) {
			setTimeout(() => {
				nameInputRef.current.focus();
			}, 100);
		} else {
			setFormFields(initialFormFields);
		}
	}, [newItemModalOpen]);

	return (
		<ItemsContext.Provider value={{setFormFields, setNewItemModalOpen}}>
			<ul className="List">
				{data && Object.entries(data).map(([id, item]) =>
					<Item
						key={id}
						collection={collection}
						item={item}
						id={id}
					/>
				)}
			</ul>
			<Modal state={[newItemModalOpen, setNewItemModalOpen]}>
				<Modal.Window>
					<header className="Modal__header">
						<h2 className="Modal__title">{formFields.id ? "Opdater vare" : "Tilføj vare"}</h2>
						<div className="Modal__buttons">
							<Button className="Modal__button" type="button" onClick={newItemCancel}>
								<svg viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path></svg>
							</Button>
						</div>
					</header>
					<form id="new-item-form" className="Modal__body" onSubmit={newItemAdd}>
						<label htmlFor="name">
							Varenavn
							<input
								ref={nameInputRef}
								id="name"
								type="text"
								value={formFields.name}
								onChange={e => setFormFields(prev => ({
									...prev,
									name: e.target.value
								}))}
							/>
						</label>
						<label htmlFor="description">
							Beskrivelse
							<input
								id="description"
								type="text"
								value={formFields.description}
								onChange={e => setFormFields(prev => ({
									...prev,
									description: e.target.value
								}))}
							/>
						</label>
						<label htmlFor="amount">
							Antal
							<div className="Modal__bodyFlex">
								<input
									ref={amountInputRef}
									id="amount"
									type="number"
									value={formFields.amount}
									onChange={e => setFormFields(prev => ({
										...prev,
										amount: e.target.value
									}))}
								/>
								<Button type="button" className="Button--small" onClick={incrementAmountInput}>
									<svg viewBox="0 0 24 24"><path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"></path></svg>
								</Button>
								<Button type="button" className="Button--small" onClick={decrementAmountInput}>
									<svg viewBox="0 0 24 24"><path d="M19,13H5V11H19V13Z"></path></svg>
								</Button>
							</div>
						</label>
						<div style={{
							display: "flex",
							justifyContent: formFields.id ? "flex-end" : "space-between",
							minWidth: "100%"
						}}>
							{formFields.id ? null : <label className="Modal__bodyCheckbox">
								<input checked={checkbox} onChange={() => setCheckbox(prev => !prev)} type="checkbox" className="Modal__bodyCheckboxInput" />
								<div className="Modal__bodyCheckboxBox" tabIndex="0">
									{checkbox && <svg className="Modal__bodyCheckboxCheckmark" viewBox="0 0 24 24"><path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"></path></svg>}
								</div>
								Luk ikke
							</label>}
							<button className="Modal__bodyButton" type="submit" onClick={newItemAdd}>{formFields.id ? "Opdater" : checkbox ? "Tilføj" : "Tilføj og luk"}</button>
						</div>
					</form>
				</Modal.Window>
			</Modal>
		</ItemsContext.Provider>
	);
}

function Item({collection, item, id}){
	var { setData, deleteData } = useDatabase();
	var {title, text, amount, collected} = item;
	var {collectedHidden} = useContext(ListContext);
	var {setFormFields, setNewItemModalOpen} = useContext(ItemsContext);
	var [expanded, setExpanded] = useState(false);
	var [deleteItemModalOpen, setDeleteItemModalOpen] = useState(false);

	function itemClickHandler(e){
		setExpanded(prev => !prev);
	}

	function checkClickHandler(e){
		e.stopPropagation();
		setData("lists/" + collection + "/items/" + id + "/collected", !collected);
	}

	function editClickHandler(e){
		e.stopPropagation();
		setFormFields({
			id,
			name: title,
			description: text,
			amount
		});
		setNewItemModalOpen(true);
	}

	function deleteClickHandler(){
		setDeleteItemModalOpen(true);
	}
	
	function deleteItemClickHandler(){
		deleteData("lists/" + collection + "/items/" + id);
		setDeleteItemModalOpen(false);
	}

	return (
		<>
			<li className={`List__item${expanded ? " List__item--expanded" : ""}${collectedHidden && collected ? " List__item--hidden" : ""}`} onClick={itemClickHandler}>
				<div className="List__icon List__check" onClick={checkClickHandler}>
					<div className="List__checkRound">
						{
							collected
								? <svg viewBox="0 0 24 24"><path d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"></path></svg>
								: null
						}
					</div>
				</div>
				<div className="List__info" style={{marginTop: text ? null : "1rem"}}>
					<p className="List__infoAmount">{amount}</p>
					<h3 className="List__infoTitle">{title}</h3>
					{text ? <p className="List__infoText">{text}</p> : <div className="List__infoEmpty">
						<p className="List__infoEmptyText" onClick={editClickHandler}>Tilføj beskrivelse</p>
						<svg viewBox="0 0 24 24" onClick={editClickHandler}><path d="M4,15V9H12V4.16L19.84,12L12,19.84V15H4Z"></path></svg>
					</div>}
				</div>
				<div className="List__icon List__expand">
					<svg viewBox="0 0 24 24"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path></svg>
				</div>
				<Button lineright linedark tabIndex="-1" className="List__button List__button--delete" onClick={deleteClickHandler}>
					<svg viewBox="0 0 24 24"><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"></path></svg>
				</Button>
				<Button lineleft linedark tabIndex="-1" className="List__button List__button--edit" onClick={editClickHandler}>
					<svg viewBox="0 0 24 24"><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"></path></svg>
				</Button>
			</li>
			<Modal state={[deleteItemModalOpen, setDeleteItemModalOpen]}>
				<Modal.Window>
					<header className="Modal__header">
						<h2 className="Modal__title">Hvad vil du slette?</h2>
						<div className="Modal__buttons">
							<Button className="Modal__button" type="button" onClick={() => setDeleteItemModalOpen(false)}>
								<svg viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path></svg>
							</Button>
						</div>
					</header>
					<div className="Modal__body">
						<p style={{
							color: "red",
							fontWeight: "700",
							margin: "0.5rem"
						}}>Slet <span style={{
							color: "purple"
						}}>{amount}x {title}</span>?</p>
						<p style={{
							fontSize: "0.8rem",
							margin: "0"
						}}>{text || "(Ingen beskrivelse af varen)"}</p>
						<button className="Modal__bodyButton" onClick={deleteItemClickHandler}>Slet</button>
					</div>
				</Modal.Window>
			</Modal>
		</>
	);
}

export default List;