import { createContext, useContext, useEffect } from "react";
import Portal from "./Portal";
import "./Modal.scss";

var OpenContext = createContext();

function useOpenContext(component, hasChildren = false){
	var context = useContext(OpenContext);
	if (!context) {
		let comp = `<Modal.${component}${hasChildren ? `></Modal.${component}>` : "/>"}`;
		throw new Error(`The modal sub-component (Modal.${component}) must be rendered inside the Modal component (<Modal>${comp}</Modal>)`);
	}
	return context;
}

function Modal({state, className = "", children, onClick, options = {}, ...other}){
	var [open, setOpen] = state;
	var {closeOnBackClick = true, closeOnEscape = true} = options;

	useEffect(() => {
		if (closeOnEscape) {
			window.addEventListener("keyup", keyPressHandler);
			return () => window.removeEventListener("keyup", keyPressHandler);
		}
		function keyPressHandler(e){
			if (e.key === "Escape") {
					setOpen(false);
			}
		}
	}, [closeOnEscape, setOpen]);

	function backgroundHandler(e){
		closeOnBackClick && setOpen(false);
		onClick && onClick(e);
	}

	return (
		<Portal>
			<OpenContext.Provider value={{open, setOpen}}>
				<div
					onClick={backgroundHandler}
					className={`Modal ${className}`}
					open={open}
					{...other}
				>
					{children}
				</div>
			</OpenContext.Provider>
		</Portal>
	);
}

var Window = ({children, className = "", tag = "aside", ...other}) => {
	useOpenContext("Window", true);
	var CustomTag = tag;
	return (
		<CustomTag
			className={`Modal__Window ${className}`}
			onClick={(e) => e.stopPropagation()}
			{...other}
		>
			{children}
		</CustomTag>
	);
}
Modal.Window = Window;

var CloseButton = ({children, className = "", ...other}) => {
	var {setOpen} = useOpenContext("CloseButton");
	var content = children || <svg viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path></svg>;
	
	return (
		<button
			onClick={() => {setOpen(false)}}
			className={`Modal__CloseButton ${className}`}
			{...other}
		>
			{content}
		</button>
	)
}
Modal.CloseButton = CloseButton;

export default Modal;