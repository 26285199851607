import { Router } from '@reach/router';
import ListView from './views/ListView';
import Login from './views/Login';
import ListManager from './views/ListManager';
import './App.scss';
import { AuthProvider } from './contexts/AuthContext';
import { DatabaseProvider } from "./contexts/DatabaseContext";
import Settings from './views/Settings';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <DatabaseProvider>
          <Router>
            <Login default path="/login" />
            <ListManager path="/" />
            <ListView path="/list/:collection" />
            <Settings path="/settings" />
          </Router>
        </DatabaseProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
