import { navigate } from "@reach/router";
import "./Lists.scss";

function Lists({data, newListClickHandler}){
	function listClickHandler(id){
		navigate("/list/" + id);
	}

	return (
		<section className="Lists">
			{data && Object.entries(data).map(([id, item]) => <button
				key={id}
				onClick={() => listClickHandler(id)}
				className="Lists__button"
			>
				{item.title.length > 48 ? item.title.substring(0, 45) + "..." : item.title}
			</button>)}
			<button
				onClick={newListClickHandler}
				className="Lists__button Lists__button--new"
			>
				<svg fill="white" viewBox="0 0 24 24"><path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"></path></svg>
				{!data && <p className="Lists__buttonExplanation">Tryk her for at oprette en ny liste</p>}
			</button>
		</section>
	);
}

export default Lists;