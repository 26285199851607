import React, { useContext } from "react";
import { db } from "../firebase";

const DatabaseContext = React.createContext();

export function useDatabase() {
  return useContext(DatabaseContext);
}

export function DatabaseProvider({ children }) {
	function getData(path, callback){
		var currentList = db.ref(path);
		currentList.on("value", snap => callback(snap.val()));
	}
	function setData(path, value, isNew=false){
		var currentList = db.ref(path);
		if (isNew) {
			currentList = currentList.push();
		}
		currentList.set(value);
	}
	function deleteData(path, callback){
		db.ref(path).remove(callback);
	}

  return (
    <DatabaseContext.Provider value={{
			getData,
			setData,
			deleteData
		}}>
      {children}
    </DatabaseContext.Provider>
  )
}