import Header from "../components/Header";
import Button from "../components/Button";
import Accordion from "../components/Accordion";
import "./Settings.scss";
import { navigate } from "@reach/router";

var fakeUsers = {
	suneseifert: {
		name: "Sune Seifert",
		email: "bqardi@msn.com",
		role: "admin"
	},
	ninavilen: {
		name: "Nina Vilen",
		email: "ninavilen@msn.com",
		role: "super"
	},
	simonvilen: {
		name: "Simon Vilen",
		email: "simonvilen03@gmail.com",
		role: "shopper"
	},
}

function Settings(){
	return (
		<article className="Settings">
			<Header>
				<Button onClick={() => navigate("/")}>
					<svg viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg>
				</Button>
				<h1 className="Settings__title">Indstillinger</h1>
			</Header>
			<main>
				<Accordion className="Settings__accordion" options={{allowMultipleOpen: false}}>
					<Accordion.Item className="Settings__accordionItem" title="Brugere">
						{Object.entries(fakeUsers).map(([key, value]) =>
							<div key={key} className="Settings__user">
								<h3 className="Settings__userName">{value.name}</h3>
								<p className="Settings__userEmail">{value.email}</p>
							</div>
						)}
					</Accordion.Item>
					<Accordion.Item className="Settings__accordionItem" title="Side">
						<form>
							<input type="text" />
						</form>
					</Accordion.Item>
				</Accordion>
			</main>
		</article>
	);
}

export default Settings;