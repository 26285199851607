import "./Header.scss";

function Header({children}){
	return (
		<header className="Header">
			{children}
		</header>
	);
}

export default Header;