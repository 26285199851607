import { navigate } from "@reach/router";
import firebase from "firebase/app";
import "firebase/database";
import { useEffect, useRef, useState } from "react";
import "./ListManager.scss";
import Header from "../components/Header";
import Button from "../components/Button";
import Modal from "../components/Modal";
import Lists from "../components/Lists";
import { useAuth } from "../contexts/AuthContext";
import { useDatabase } from "../contexts/DatabaseContext";

function ListManager(){
	const { logout, currentUser } = useAuth();
	const { getData } = useDatabase();
	var [lists, setLists] = useState([]);
	var [confirmLogout, setConfirmLogout] = useState(false);
	var [newListForm, setNewListForm] = useState(false);
	var [inputValue, setInputValue] = useState("");

	var inputRef = useRef();

	function logoutHandler(){
		logout();
		navigate("/login");
	}

	function newListClickHandler(e){
		e.preventDefault();
		if (!inputValue) {
			inputRef.current.focus();
			return;
		}
		var newList = firebase.database().ref("lists");
		var currentList = newList.push();
		currentList.set({
			title: inputValue
		});
		setNewListForm(false);
		navigate("/list/" + currentList.key);
	}

	function newListFormClickHandler(){
		setNewListForm(true);
		setTimeout(() => {
			inputRef.current.focus();
		}, 100);
	}

	useEffect(() => {
		if (!currentUser) {
			navigate("/login");
			return;
		}
		var isMounted = true;
		getData("lists", snapVal => isMounted && setLists(snapVal));
		return () => isMounted = false;
	}, [currentUser, getData]);

	return (
		<article className="ListManager">
			<Header>
				<Button onClick={() => setConfirmLogout(true)}>
					<svg viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg>
				</Button>
				<Button style={{padding: "0.5rem"}} onClick={() => navigate("/settings")}>
					<svg viewBox="0 0 24 24"><path d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z"></path></svg>
				</Button>
			</Header>
			<main className="ListManager__main">
				<Lists
					data={lists}
					newListClickHandler={newListFormClickHandler}
				/>
			</main>
			<Modal state={[confirmLogout, setConfirmLogout]}>
				<Modal.Window>
					<header className="Modal__header">
						<h2 className="Modal__title">Log ud?</h2>
						<div className="Modal__buttons">
							<Button className="Modal__button" type="button" onClick={() => setConfirmLogout(false)}>
								<svg viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path></svg>
							</Button>
						</div>
					</header>
					<div className="Modal__body">
						<p>Er du sikker på at du vil logge ud?</p>
						<button className="Modal__bodyButton" onClick={logoutHandler}>Ja, jeg er helt sikker!</button>
					</div>
				</Modal.Window>
			</Modal>
			<Modal state={[newListForm, setNewListForm]}>
				<Modal.Window>
					<header className="Modal__header">
						<h2 className="Modal__title">Opret ny liste</h2>
						<div className="Modal__buttons">
							<Button className="Modal__button" type="button" onClick={() => setNewListForm(false)}>
								<svg viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path></svg>
							</Button>
						</div>
					</header>
					<form onSubmit={newListClickHandler} className="Modal__body">
						<label>
							Navn
							<input ref={inputRef} type="text" value={inputValue} onChange={e => setInputValue(e.target.value)} />
						</label>
						<button className="Modal__bodyButton" type="submit">Opret</button>
					</form>
				</Modal.Window>
			</Modal>
		</article>
	);
}

export default ListManager;