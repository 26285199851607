import { createContext, useEffect, useRef, useState } from "react";
import List from "../components/List";
import Header from "../components/Header";
import Button from "../components/Button";
import Modal from "../components/Modal";
import "firebase/database";
import "./ListView.scss";
import { useDatabase } from "../contexts/DatabaseContext";
import { navigate } from "@reach/router";

export var ListContext = createContext();

function ListView({collection}){
	const { getData, setData, deleteData } = useDatabase();
	var [content, setContent] = useState([]);
	var [collectedHidden, setCollectedHidden] = useState(false);
	var [newItemModalOpen, setNewItemModalOpen] = useState(false);
	var [deleteListModalOpen, setDeleteListModalOpen] = useState(false);
	var [noContentModalOpen, setNoContentModalOpen] = useState(false);
	var [hasDeleted, setHasDeleted] = useState(false);
	var [listTitleInput, setListTitleInput] = useState(false);

	var noContentRef = useRef();
	var inputRef = useRef();

	function clickHideCollectedHandler(){
		setCollectedHidden(prev => !prev);
	}

	function newItemClickHandler(){
		setNewItemModalOpen(true);
	}

	function deleteHandler(){
		setDeleteListModalOpen(true);
	}

	function noContentNavigate(){
		clearTimeout(noContentRef.current);
		navigate("/");
	}

	function noContentHandler(){
		setNoContentModalOpen(true);
		noContentRef.current = setTimeout(() => {
			setNoContentModalOpen(false);
			noContentRef.current = setTimeout(() => {
				navigate("/");
			}, 300);
		}, 3700);
	}

	function deleteListClickHandler(){
		setHasDeleted(true);
		deleteData("lists/" + collection, error => {
			if (error) {
				console.log(error);
			}
			setHasDeleted(false);
			setTimeout(() => {
				navigate("/");
			}, 300);
		});
		setDeleteListModalOpen(false);
	}

	function deleteCollectedClickHandler(){
		var remainingItems = Object.entries(content.items).filter(([key, value]) => !value.collected);
		var obj = {}
		remainingItems.forEach(([key, value]) => {
			obj[key] = value;
		});
		setData("lists/" + collection + "/items", obj);
		setDeleteListModalOpen(false);
	}

	function showListTitleInput(){
		setListTitleInput(true);
		setTimeout(() => {
			inputRef.current.focus();
			inputRef.current.click();
		});
	}

	function updateListTitleInput(){
		setListTitleInput(false);
		if (inputRef.current.value !== content?.title) {
			setData("lists/" + collection + "/title", inputRef.current.value);
		}
	}

	function listTitleInputKeyHandler(e){
		if (e.key === "Enter") {
			updateListTitleInput();
		}
	}

	useEffect(() => {
		var isMounted = true;
		getData("lists/" + collection, snapVal => isMounted && setContent(snapVal));
		return () => isMounted = false;
	}, [collection, getData]);

	useEffect(() => {
		if (content === null && !hasDeleted) {
			noContentHandler();
			return;
		}
		// eslint-disable-next-line
	}, [content]);

	return (
		<ListContext.Provider value={{
			newItemModalOpen, setNewItemModalOpen,
			collectedHidden
		}}>
			<Header>
				<Button to="/">
					<svg viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg>
				</Button>
				{
					listTitleInput
						? <input style={{
							fontSize: "1.2rem",
							fontWeight: "700",
							textAlign: "center",
							color: "white",
							backgroundColor: "transparent",
							border: "none",
							boxSizing: "border-box",
							width: "100%",
							padding: "0.5rem 0",
							fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						}}
						type="text"
						ref={inputRef}
						defaultValue={content?.title}
						onBlur={updateListTitleInput}
						onKeyUp={listTitleInputKeyHandler}
					/>
						: <h1 onClick={showListTitleInput}>{content?.title}</h1>
				}
				<div style={{
					display: "flex"
				}}>
					<Button onClick={clickHideCollectedHandler} style={{
						backgroundColor: collectedHidden ? "#c000c0" : null
					}}>
						<svg viewBox="0 0 24 24"><path d="M3,5H9V11H3V5M5,7V9H7V7H5M11,7H21V9H11V7M11,15H21V17H11V15M5,20L1.5,16.5L2.91,15.09L5,17.17L9.59,12.59L11,14L5,20Z"></path></svg>
					</Button>
				</div>
			</Header>
			<main className="ListView">
				<List collection={collection} data={content?.items} />
			</main>
			<Button
				style={{
					position: "fixed",
					left: "0.5rem",
					bottom: "0.5rem",
					backgroundColor: "purple",
					borderRadius: "50%",
					padding: "0.5rem"
				}}
				onClick={deleteHandler}
			>
				<svg fill="white" viewBox="0 0 24 24"><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"></path></svg>
			</Button>
			<Button
				style={{
					position: "fixed",
					right: "0.5rem",
					bottom: "0.5rem",
					backgroundColor: "purple",
					borderRadius: "50%",
					padding: "0.5rem"
				}}
				onClick={newItemClickHandler}
			>
				<svg fill="white" viewBox="0 0 24 24"><path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"></path></svg>
				{!content?.items && <p className="ListView__explanation">Opret ny vare</p>}
			</Button>
			<Modal state={[deleteListModalOpen, setDeleteListModalOpen]}>
				<Modal.Window>
					<header className="Modal__header">
						<h2 className="Modal__title">Hvad vil du slette?</h2>
						<div className="Modal__buttons">
							<Button className="Modal__button" type="button" onClick={() => setDeleteListModalOpen(false)}>
								<svg viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path></svg>
							</Button>
						</div>
					</header>
					<div className="Modal__body">
						<div className="Modal__bodyFlex">
							<p>Slet listen "{content?.title}"?</p>
							<button className="Modal__bodyButton" onClick={deleteListClickHandler}>Listen</button>
						</div>
						<div className="Modal__bodyFlex">
							<p>Slet alle markerede?</p>
							<button className="Modal__bodyButton" onClick={deleteCollectedClickHandler}>Markerede</button>
						</div>
					</div>
				</Modal.Window>
			</Modal>
			<Modal state={[noContentModalOpen, setNoContentModalOpen]} options={{closeOnBackClick: false, closeOnEscape: false}}>
				<Modal.Window>
					<header
						className="Modal__header"
						style={{
							height: "var(--button-size)"
						}}
					>
						<h2 className="Modal__title">Noget gik galt</h2>
					</header>
					<div className="Modal__body">
						<p>Listen ser ud til at være blevet slettet andet steds fra!</p>
						<p>Du bliver sendt tilbage til forsiden!</p>
						<button className="Modal__bodyButton" onClick={noContentNavigate}>Tilbage</button>
					</div>
				</Modal.Window>
			</Modal>
		</ListContext.Provider>
	);
}

export default ListView;