import { useRef, useState } from "react";
import {useAuth} from "../contexts/AuthContext";
import {navigate} from "@reach/router";
import "./Login.scss";

function Login(){
	const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

	const emailRef = useRef();
	const passwordRef = useRef();

	async function handleSubmit(e) {
    e.preventDefault();

		if (!emailRef.current.value) {
			setError("Email skal udfyldes!");
			emailRef.current.focus();
			return;
		}
		if (!passwordRef.current.value) {
			setError("Adgangskode skal udfyldes!");
			passwordRef.current.focus();
			return;
		}

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      navigate("/");
    } catch {
      setError("Fejl i email eller adgangskode!");
    }

    setLoading(false);
  }

	return (
		<article className="Login" onSubmit={handleSubmit}>
			<h2>Log ind</h2>
			{error && <p className="Login__error">{error}</p>}
			<form className="Login__form">
				<label className="Login__label">
					Email
					<input ref={emailRef} name="initials" className="Login__input" type="email" />
				</label>
				<label className="Login__label">
					Adgangskode
					<input ref={passwordRef} name="password" className="Login__input" type="password" />
				</label>
				<button disabled={loading} className="Login__button" type="submit">Log in</button>
			</form>
		</article>
	);
}

export default Login;