import { Link } from "@reach/router";
import "./Button.scss";

function Button({children, to, className, lineleft, lineright, linedark, ...other}){
	return to
		? <Link to={to} className={`Button${lineleft ? " Button--lineleft" : ""}${lineright ? " Button--lineright" : ""}${linedark ? " Button--linedark" : ""}${className ? " " + className : ""}`} {...other}>{children}</Link>
		: <button className={`Button${lineleft ? " Button--lineleft" : ""}${lineright ? " Button--lineright" : ""}${linedark ? " Button--linedark" : ""}${className ? " " + className : ""}`} {...other}>
		{children}
	</button>;
}

export default Button;